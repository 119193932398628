import React from 'react'
import { withTheme } from 'emotion-theming'
import GlobalCss from './GlobalCss.styles'

const GlobalStyles = withTheme(() => (
  <>
    <GlobalCss />
  </>
))

export default GlobalStyles
